import React, { useState, useEffect } from "react";
import MainLayout from "./MainLayout";
import UserService from "../../Services/UserList";
import Swal from "sweetalert2";
import Loader from "../utils/Loader";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  TextField,
  Box,
} from "@mui/material";

const Log = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  // Fetch data from the API
  const userLog = async () => {
    try {
      setLoading(true);
      const response = await UserService.loglist();
      setLoading(false);
      setData(response.data);
    } catch (error) {
      setLoading(false);
      Swal.fire({
        icon: "warning",
        title: "Warning",
        text: error.message || "Something went wrong!",
        timer: 3000,
      });
    }
  };

  useEffect(() => {
    userLog();
  }, []);

  const filteredData = data.filter((item) => {
    const query = searchQuery.toLowerCase();
    return (
      query === "" ||
      (item.empCode && item.empCode.toLowerCase().includes(query)) ||
      (item.name && item.name.toLowerCase().includes(query))
    );
  });

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <MainLayout>
      {loading && <Loader />}

      <Box sx={{ p: 3 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            marginBottom: 3,
            padding: 2,
          }}
        >
          <TextField
            label="Search Projects"
            variant="outlined"
            size="small"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            sx={{
              width: "100%",
              maxWidth: 400,
              // borderRadius: '50px',
              backgroundColor: "#f0f0f0",
              boxShadow: "0px 4px 6px rgba(0,0,0,0.1)",
            }}
          />
        </Box>

        {/* Table */}
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center" sx={{ fontWeight: "bold" }}>
                  Sr. No
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: "bold" }}>
                  EmpCode
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: "bold" }}>
                  Name
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: "bold" }}>
                  IP
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: "bold" }}>
                  Date
                </TableCell>
                <TableCell align="center" sx={{ fontWeight: "bold" }}>
                  Time
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item, index) => (
                  <TableRow key={item.id}>
                    <TableCell align="center">
                      {page * rowsPerPage + index + 1}
                    </TableCell>
                    <TableCell align="center">{item.empCode}</TableCell>
                    <TableCell align="center">{item.name}</TableCell>
                    <TableCell align="center">{item.ip}</TableCell>
                    <TableCell align="center">
                      {item
                        ? new Date(item.created_at).toLocaleDateString("en-GB")
                        : ""}
                    </TableCell>
                    <TableCell align="center">
                      {new Date(item.updated_at).toLocaleString("en-GB", {
                        hour: "2-digit",
                        minute: "2-digit",
                        hour12: false,
                      })}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>

        {/* Pagination */}
        <TablePagination
          component="div"
          count={filteredData.length}
          page={page}
          onPageChange={handlePageChange}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleRowsPerPageChange}
          rowsPerPageOptions={[10, 20, 50, 100, 500, 1000, filteredData.length]}
        />
      </Box>
    </MainLayout>
  );
};

export default Log;
