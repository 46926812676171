import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import UserService from "../Services/UserList";
import SidebarNavigation from "./SidebarNavigation";
import MainLayout from "./admin/MainLayout";
import { useNavigate } from "react-router-dom";
export default function EditUserList() {
  const navigate = useNavigate();
  const { id } = useParams();
  // const [users, setUsers] = useState([]);
  const [formData, setFormData] = useState({
    name: "",
    last_name: "",
    email: "",
    city: "",
    state: "",
    is_active: "",
    emp_code: "",
  });

  useEffect(() => {
    userlistapi();
  }, []);
  const userlistapi = async () => {
    try {
      const response = await UserService.EditUser(id);
      // setUsers(response.data.data);
      setFormData(response.data);
      console.log(formData);
    } catch (error) {
      Swal.fire({
        icon: "warning",
        title: "warning",
        text: error,
        timer: 3000, // Automatically close after 3 seconds
      });
    }
  };
  console.log(setFormData);
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await UserService.UpdateUser(formData);
      console.log(response);
      if (response) {
        Swal.fire({
          icon: "success",
          title: "user",
          text: "user update successfully",
          timer: 3000, // Automatically close after 3 seconds
        });
        navigate("/admin/userlist");
      }
      console.log(formData);
    } catch (error) {
      // console.log(error.errors);
      Swal.fire({
        icon: "warning",
        title: "warning",
        text: error.errors,
        timer: 3000, // Automatically close after 3 seconds
      });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  return (
    <MainLayout>
      <div className="">
        <form
          onSubmit={handleSubmit}
          className="relative bg-gradient-to-br h-auto from-blue-50 to-indigo-100 shadow-lg border border-gray-200 rounded-2xl p-8 max-w-4xl mx-auto"
        >
          <div className="space-y-8">
            {/* Form Fields */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-x-8 gap-y-6">
              {/* First Name */}
              <div>
                <label
                  htmlFor="name"
                  className="block text-sm font-medium text-gray-700"
                >
                  First Name
                </label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  value={formData.name}
                  onChange={handleChange}
                  placeholder="Enter First Name"
                  className="mt-2 block w-full rounded-lg border border-gray-300 bg-white px-4 py-3 text-gray-800 focus:ring-4 focus:ring-indigo-400 focus:outline-none transition-shadow duration-300 shadow-sm placeholder:text-gray-400 sm:text-base"
                />
              </div>

              {/* Last Name */}
              <div>
                <label
                  htmlFor="last_name"
                  className="block text-sm font-medium text-gray-700"
                >
                  Last Name
                </label>
                <input
                  type="text"
                  name="last_name"
                  id="last_name"
                  value={formData.last_name}
                  onChange={handleChange}
                  placeholder="Enter Last Name"
                  className="mt-2 block w-full rounded-lg border border-gray-300 bg-white px-4 py-3 text-gray-800 focus:ring-4 focus:ring-indigo-400 focus:outline-none transition-shadow duration-300 shadow-sm placeholder:text-gray-400 sm:text-base"
                />
              </div>

              {/* Email */}
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email Address
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="example@domain.com"
                  className="mt-2 block w-full rounded-lg border border-gray-300 bg-white px-4 py-3 text-gray-800 focus:ring-4 focus:ring-indigo-400 focus:outline-none transition-shadow duration-300 shadow-sm placeholder:text-gray-400 sm:text-base"
                />
              </div>

              {/* Employee Code */}
              <div>
                <label
                  htmlFor="empCode"
                  className="block text-sm font-medium text-gray-700"
                >
                  Employee Code
                </label>
                <input
                  type="text"
                  name="empCode"
                  id="empCode"
                  value={formData.emp_code}
                  onChange={handleChange}
                  placeholder="EMP1234"
                  className="mt-2 block w-full rounded-lg border border-gray-300 bg-white px-4 py-3 text-gray-800 focus:ring-4 focus:ring-indigo-400 focus:outline-none transition-shadow duration-300 shadow-sm placeholder:text-gray-400 sm:text-base"
                />
              </div>

              {/* City */}
              <div>
                <label
                  htmlFor="city"
                  className="block text-sm font-medium text-gray-700"
                >
                  City
                </label>
                <input
                  type="text"
                  name="city"
                  id="city"
                  value={formData.city}
                  onChange={handleChange}
                  placeholder="Enter City "
                  className="mt-2 block w-full rounded-lg border border-gray-300 bg-white px-4 py-3 text-gray-800 focus:ring-4 focus:ring-indigo-400 focus:outline-none transition-shadow duration-300 shadow-sm placeholder:text-gray-400 sm:text-base"
                />
              </div>

              {/* State */}
              <div>
                <label
                  htmlFor="state"
                  className="block text-sm font-medium text-gray-700"
                >
                  State / Province
                </label>
                <input
                  type="text"
                  name="state"
                  id="state"
                  value={formData.state}
                  onChange={handleChange}
                  placeholder="Enter State"
                  className="mt-2 block w-full rounded-lg border border-gray-300 bg-white px-4 py-3 text-gray-800 focus:ring-4 focus:ring-indigo-400 focus:outline-none transition-shadow duration-300 shadow-sm placeholder:text-gray-400 sm:text-base"
                />
              </div>

              {/* Status */}
              <div>
                <label
                  htmlFor="is_active"
                  className="block text-sm font-medium text-gray-700"
                >
                  Status
                </label>
                <select
                  name="is_active"
                  id="is_active"
                  value={formData.is_active}
                  onChange={handleChange}
                  className="mt-2 block w-full rounded-lg border border-gray-300 bg-white px-4 py-3 text-gray-800 focus:ring-4 focus:ring-indigo-400 focus:outline-none transition-shadow duration-300 shadow-sm sm:text-base"
                >
                  <option value="0">Deactive</option>
                  <option value="1">Active</option>
                </select>
              </div>
            </div>

            {/* Action Buttons */}
            <div className="flex justify-center items-center space-x-6 mt-6">
              <button
                type="submit"
                className="px-8 py-3 rounded-lg bg-gradient-to-r from-indigo-500 to-purple-500 text-white font-semibold shadow-lg hover:from-indigo-600 hover:to-purple-600 focus:ring-4 focus:ring-indigo-300 focus:outline-none transition duration-300"
              >
                Save
              </button>
              <button
                type="button"
                onClick={() => navigate("/admin/userlist")}
                className="px-8 py-3 rounded-lg bg-gray-100 border border-gray-300 text-gray-700 font-semibold shadow-lg hover:bg-gray-200 focus:ring-4 focus:ring-gray-300 focus:outline-none transition duration-300"
              >
                Cancel
              </button>
            </div>
          </div>
        </form>
      </div>
    </MainLayout>
  );
}
