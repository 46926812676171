import Swal from 'sweetalert2';
export const handleApiError = (error, customErrorMessage = 'An error occurred.') => {
  if (error.response) {
    // console.log( error.response.data.message);
    var message;
    // The request was made and the server responded with a status code
    // console.error('API Error Status:', error.response.status);
    // console.error('API Error Data:', error.response.data);
    // console.log('Error message',error.response.data.message);
    message = error.response.data.message ? error.response.data.message : error.response.status;


  }
  else if (error.error) {
    message = error.error;
  }
  else if (error.message) {
    message = error.message;
  }
  else if (error.response.data) {
    message = error.response.data;
  }
  else if (error.request) {
    // The request was made but no response was received
    // console.error('API Error Request:', error.request);
    message = error.request;
    // Handle network-related errors here (e.g., no internet connection)
  }
  else if (error.errors) {
    message = error.errors;
  }
  else {
    // Something happened in setting up the request
    // console.error('API Error Message:', error.message);
    message = error.message;
    // Handle other errors (e.g., request configuration issues)
  }
  // You can display a user-friendly error message or trigger some action here
  if (message) {
    Swal.fire({
      icon: 'error',
      // title: message,
      text: message,
    });
  }
  else if (customErrorMessage) {
    Swal.fire({
      icon: 'error',
      // title: 'error',
      text: { customErrorMessage },
    });
  }
};