// Autocomplete.js
import React, { useState, useEffect, useRef } from 'react';
import UserSection from '../../Services/User/UserLogin';
import { FaSearch } from 'react-icons/fa';
const Autocomplete = ({ suggestions = [], onSelected }) => {
    const [inputValue, setInputValue] = useState('');
    const [filteredSuggestions, setFilteredSuggestions] = useState([]);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const inputRef = useRef(null);

    // useEffect(() => {
    //     setFilteredSuggestions(suggestions.filter(suggestion =>
    //         suggestion.toLowerCase().includes(inputValue.toLowerCase())
    //     ));
    // }, [inputValue, suggestions]);

    const handleClickOutside = (event) => {
        if (inputRef.current && !inputRef.current.contains(event.target)) {
            setShowSuggestions(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleInputChange = (event) => {
        setInputValue(event.target.value);
        setShowSuggestions(true);
    };

    const handleSuggestionClick = (suggestion) => {

        setInputValue(suggestion.product_desc);
        setShowSuggestions(false);
        onSelected(suggestion);
    };
    const handleKeyUp = async (e) => {
        try {
            // setName(e.target.value);
            const response = await UserSection.byProduct(e.target.value);
            setFilteredSuggestions(response.data);

        } catch (error) {

        }

    }
    return (
        <div ref={inputRef} className=" w-[25rem]">
            <div className=" relative">
                <input
                    type="search"
                    value={inputValue}
                    onKeyUp={handleKeyUp}
                    onChange={handleInputChange}
                    placeholder="Search to Add New SKU"
                    className="w-full p-3 pl-5 border  border-gray-500 rounded-md text-gray-700 placeholder-gray-400 focus:outline-none focus:border-blue-500 transition duration-150"
                />
                {inputValue.length === 0 && (
                    <span className="absolute inset-y-0 right-0 flex items-center pr-3">
                        <FaSearch className="text-blue-900" />
                    </span>
                )}

                {showSuggestions && (
                    <ul className="absolute w-full bg-white border border-gray-200 rounded-md shadow-md mt-1 z-10">
                        {filteredSuggestions.map((suggestion, index) => (
                            <li
                                key={index}
                                onClick={() => handleSuggestionClick(suggestion)}
                                className="p-2 hover:bg-blue-100 cursor-pointer text-sm text-gray-700 transition-colors duration-150"
                            >
                                {suggestion.product_desc} / {suggestion.sku_code}
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        </div>

    );
};

export default Autocomplete;
