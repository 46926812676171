import React, { useState, useEffect } from "react";

const Carousel = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
    }, 3000); // 3-second delay

    return () => clearTimeout(timer);
  }, [currentIndex, images.length]);

  return (
    // <div className="carousel">
    //   <img
    //     src={images[currentIndex]}
    //     alt={`Slide ${currentIndex}`}
    //     className=""
    //   />
    // </div>
    <div className="absolute inset-0 -z-20">
      <img
        src={images[currentIndex]}
        alt="login"
        className="w-full h-full object-cover object-center"
      />
      {/* Overlay */}
      <div className="absolute inset-0 bg-black opacity-30"></div>
    </div>
  );
};

export default Carousel;
