import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Select,
  MenuItem,
  TablePagination,
  CircularProgress,
} from "@mui/material";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import MainLayout from "./MainLayout";
import UserService from "../../Services/UserList";
import axios from "axios";

const HelpDeskList = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0); // For Material-UI pagination
  const [rowsPerPage, setRowsPerPage] = useState(10); // Number of items per page

  const helpdesk = async () => {
    try {
      setLoading(true);
      const response = await UserService.helpDeskList();
      setLoading(false);
      setData(response.data);
    } catch (error) {
      setLoading(false);
      Swal.fire({
        icon: "warning",
        title: "Warning",
        text: error.message,
        timer: 3000,
      });
    }
  };

  const change_status = async (e, id) => {
    const status = e.target.value;
    try {
      setLoading(true);
      const user = localStorage.getItem("user");
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user}`,
      };

      const response = await axios.post(
        "https://quatationapis.groheapp.in/api/admin/userlist/help/desk/status",
        { status, id },
        { headers }
      );

      setLoading(false);
      setData(response.data.data);
    } catch (error) {
      setLoading(false);
      navigate("/admin/userlist");
      Swal.fire({
        icon: "warning",
        title: "Warning",
        text: error.message,
        timer: 3000,
      });
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    helpdesk();
  }, []);

  return (
    <MainLayout>
      {loading ? (
        <div
          style={{ display: "flex", justifyContent: "center", marginTop: 50 }}
        >
          <CircularProgress />
        </div>
      ) : (
        <Paper sx={{ margin: "20px", padding: "10px" }}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <strong>Ticket ID</strong>
                  </TableCell>
                  <TableCell>
                    <strong>Email</strong>
                  </TableCell>
                  <TableCell>
                    <strong>Category</strong>
                  </TableCell>
                  <TableCell>
                    <strong>Message</strong>
                  </TableCell>
                  <TableCell>
                    <strong>Status</strong>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((item) => (
                    <TableRow key={item.id}>
                      <TableCell>{item.id}</TableCell>
                      <TableCell>{item.email}</TableCell>
                      <TableCell>{item.categoery}</TableCell>
                      <TableCell>{item.suggestion}</TableCell>
                      <TableCell>
                        <Select
                          value={item.status}
                          onChange={(e) => change_status(e, item.id)}
                          fullWidth
                        >
                          <MenuItem value="open">Open</MenuItem>
                          <MenuItem value="close">Close</MenuItem>
                        </Select>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={data.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[10, 20, 30, 100, 500, data.length]}
          />
        </Paper>
      )}
    </MainLayout>
  );
};

export default HelpDeskList;
