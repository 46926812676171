import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import Homepage from "./Homepage1";
import authService from "../Services/auth";
import Swal from "sweetalert2";
import { handleApiError } from "../Helpers/handleApiError ";
import { handleApiSuccess } from "../Helpers/handleApiSuccess ";
import { useNavigate } from "react-router-dom";
import Carousel from "./Carousel";
import img1 from "../../src/SliderImage/1.jpg";
import img3 from "../../src/SliderImage/3.jpg";
import img4 from "../../src/SliderImage/4.jpg";
import img5 from "../../src/SliderImage/5.jpg";
import img6 from "../../src/SliderImage/6.jpg";
import img7 from "../../src/SliderImage/7.jpg";
import img8 from "../../src/SliderImage/8.jpg";
import grohe from "../../src/clientlogo/index_grohe_logo.jpg";
import americanStandard from "../../src/clientlogo/index_as_logo.gif";
import lixil from "../../src/clientlogo/index_lixil_logo.gif";

// const images = [
//   "https://grohelogin.ruminateitsolution.com/3N.jpg",
//   "https://grohelogin.ruminateitsolution.com/6N.jpg",
//   "https://grohelogin.ruminateitsolution.com/4N.jpg",
// ];

export default function Login() {
  const navigate = useNavigate();
  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const toggleProfileMenu = () => {
    setShowProfileMenu(!showProfileMenu);
  };

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isActive, setIsActive] = useState(false);
  const images = [img1, img3, img4, img5, img6, img7, img8];
  const clientLogos = [
    { src: grohe, alt: "Grohe" },
    { src: americanStandard, alt: "American Standard" },
    { src: lixil, alt: "Lixil" },
  ];

  // check for a token when component mounts
  // useEffect(() => {
  //   const token = Cookies.get('token');
  //   if (token) {
  //     setIsActive(true);
  //   }
  // }, [])

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await authService.authlogin({ email, password });
      console.log(response);
      if (
        response.status &&
        response.data.data.role_id == 1 &&
        response.data.data.is_active == 1
      ) {
        handleApiSuccess(response.message, "Success");
        localStorage.setItem("user", response.data.token);
        console.log(localStorage.getItem("user"));
        // navigate('/admin/home');
        window.location.href = "/admin/home";
      } else {
        alert("We don't assign any permission");
      }
      console.log(response);
    } catch (error) {
      handleApiError(error, "Failed to fetch data. Please try again later.");
      console.error(error);
    }
  };
  // Add a logout function to remove the token
  const handleLogout = () => {
    Cookies.remove("token");
    setIsActive(false);
  };
  return (
    <>
      <Carousel images={images} />
      <div className="fixed top-4 left-4 p-4 rounded-lg shadow-lg border border-gray-200 pointer-events-none">
        {/* First Row: Two Images */}
        <div className="flex justify-between gap-4 mb-3">
          {clientLogos.slice(0, 2).map((logo, index) => (
            <div
              key={index}
              className="p-2 "
              style={{
                backgroundColor: index === 0 ? "#111F39" : "#fff",
              }}
            >
              <img src={logo.src} alt={logo.alt} className="w-16 h-8" />
            </div>
          ))}
        </div>

        {/* Divider */}
        <hr className="border-t border-gray-200 mb-3" />

        {/* Third Image and Text */}
        <div className="flex justify-end items-center">
          <p className="text-white text-xs font-semibold">PART OF</p>
          <div className="p-2 ">
            <p className="text-orange-500 bg-white p-1 text-xs font-semibold">
              LIXIL
            </p>
          </div>
        </div>
      </div>
      <div className="max-w-md m-auto  mt-[10%] bg-gray-950/50 rounded-lg ">
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8 mt-20">
          <div className="fixed -z-50 left-0 bottom-0 top-0"></div>

          <div className="sm:mx-auto sm:w-full sm:max-w-sm ">
            {/* <img className="mx-auto h-10 w-auto" src="/grohe.png" alt="Your Company" /> */}
            <h2 className="text-center text-2xl font-bold leading-9 tracking-tight text-white">
              Sign in to your account
            </h2>
          </div>

          <div className="sm:mx-auto sm:w-full sm:max-w-sm ">
            <form className="space-y-6" action="#" method="POST">
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium leading-6 text-gray-900 text-white"
                >
                  Email address
                </label>
                <div className="mt-2">
                  <input
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    className="text-center block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div>
                <div className="flex items-center justify-between">
                  <label
                    htmlFor="password"
                    className="block text-sm font-medium leading-6 text-gray-900 text-white"
                  >
                    Password
                  </label>
                </div>
                <div className="mt-2">
                  <input
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    required
                    className="text-center block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div>
                <button
                  onClick={handleLogin}
                  type="submit"
                  className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Sign in
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
