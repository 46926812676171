import React, { useState } from "react";
import Swal from "sweetalert2";
import addUserService from "../Services/addUser";
import { handleApiError } from "../Helpers/handleApiError ";
import { handleApiSuccess } from "../Helpers/handleApiSuccess ";
import Homepage from "./Homepage1";
import MainLayout from "./admin/MainLayout";
import Lottie from "lottie-react";
import { Navigate, useNavigate } from "react-router-dom";
import animationData from "../animations/loader_animation0.json";
export default function FormAddUser() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    last_name: "",
    email: "",
    city: "",
    state: "",
    emp_code: "",
  });

  const [loading, setLoading] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const response = await addUserService.addUser(formData);
      console.log(response);
      if (response.status) {
        handleApiSuccess(response.message, "Success");
        setLoading(false);
        navigate("/admin/userlist");
      } else {
        setLoading(false);
        handleApiError(
          response,
          "Failed to fetch data. Please try again later."
        );
      }
    } catch (error) {
      console.log(error);
      handleApiError(error, "Failed to fetch data. Please try again later.");
      setLoading(false);
      Swal.fire({
        icon: "warning",
        title: "warning",
        text: error,
        timer: 3000, // Automatically close after 3 seconds
      });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  return (
    <MainLayout>
      {loading ? (
        <div className="loder">
          <Lottie
            animationData={animationData}
            loop={true}
            autoplay={true}
            style={{ height: "100px", width: "100px" }}
          />
        </div>
      ) : (
        <form
          onSubmit={handleSubmit}
          className="bg-gradient-to-br from-blue-50 to-indigo-100 p-8 rounded-lg shadow-lg max-w-4xl mx-auto mt-10 space-y-8"
        >
          {/* Form Fields */}
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
            {/* First Name */}
            <div>
              <label
                htmlFor="name"
                className="block text-sm font-medium text-gray-700"
              >
                First Name
              </label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="Enter First Name"
                className="mt-2 w-full px-4 py-2 rounded-md shadow-sm border-gray-300 focus:ring-indigo-500 focus:border-indigo-500 transition"
              />
            </div>

            {/* Last Name */}
            <div>
              <label
                htmlFor="last_name"
                className="block text-sm font-medium text-gray-700"
              >
                Last Name
              </label>
              <input
                type="text"
                id="last_name"
                name="last_name"
                value={formData.last_name}
                onChange={handleChange}
                placeholder="Enter Last Name"
                className="mt-2 w-full px-4 py-2 rounded-md shadow-sm border-gray-300 focus:ring-indigo-500 focus:border-indigo-500 transition"
              />
            </div>

            {/* Email */}
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Email Address
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="example@domain.com"
                className="mt-2 w-full px-4 py-2 rounded-md shadow-sm border-gray-300 focus:ring-indigo-500 focus:border-indigo-500 transition"
              />
            </div>

            {/* Employee Code */}
            <div>
              <label
                htmlFor="emp_code"
                className="block text-sm font-medium text-gray-700"
              >
                Employee Code
              </label>
              <input
                type="text"
                id="emp_code"
                name="emp_code"
                value={formData.emp_code}
                onChange={handleChange}
                placeholder="Enter Employee Code"
                className="mt-2 w-full px-4 py-2 rounded-md shadow-sm border-gray-300 focus:ring-indigo-500 focus:border-indigo-500 transition"
              />
            </div>

            {/* City */}
            <div>
              <label
                htmlFor="city"
                className="block text-sm font-medium text-gray-700"
              >
                City
              </label>
              <input
                type="text"
                id="city"
                name="city"
                value={formData.city}
                onChange={handleChange}
                placeholder="Enter City"
                className="mt-2 w-full px-4 py-2 rounded-md shadow-sm border-gray-300 focus:ring-indigo-500 focus:border-indigo-500 transition"
              />
            </div>

            {/* State */}
            <div>
              <label
                htmlFor="state"
                className="block text-sm font-medium text-gray-700"
              >
                State / Province
              </label>
              <input
                type="text"
                id="state"
                name="state"
                value={formData.state}
                onChange={handleChange}
                placeholder="Enter State"
                className="mt-2 w-full px-4 py-2 rounded-md shadow-sm border-gray-300 focus:ring-indigo-500 focus:border-indigo-500 transition"
              />
            </div>
          </div>

          {/* Buttons */}
          <div className="flex justify-center gap-4">
            <button
              type="submit"
              className="bg-gradient-to-r from-indigo-500 to-purple-500 text-white px-6 py-2 rounded-lg shadow-lg hover:from-indigo-600 hover:to-purple-600 focus:outline-none focus:ring-2 focus:ring-indigo-300 transition"
            >
              Save
            </button>
            {/* <button
              type="button"
              onClick={() =>navigate}
              className="bg-gray-100 text-gray-700 px-6 py-2 rounded-lg shadow-lg hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-300 transition"
            >
              Cancel
            </button> */}
          </div>
        </form>
      )}
    </MainLayout>
  );
}
