import Carousel from "../Carousel";
import MainLayout from "./MainLayout";
import img1 from "../../../src/SliderImage/1.jpg";
import img3 from "../../../src/SliderImage/3.jpg";
import img4 from "../../../src/SliderImage/4.jpg";
import img5 from "../../../src/SliderImage/5.jpg";
import img6 from "../../../src/SliderImage/6.jpg";
import img7 from "../../../src/SliderImage/7.jpg";
import img8 from "../../../src/SliderImage/8.jpg";

const Homepage = () => {
  const images = [img1, img3, img4, img5, img6, img7, img8];

  return (
    <>
      <MainLayout />
    </>
  );
};
export default Homepage;
