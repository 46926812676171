import React, { useEffect, useState } from "react";
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  Typography,
  IconButton,
  Badge,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import GradeIcon from "@mui/icons-material/Grade";

const NotificationDrawer = ({
  initialErrorMessages,
  onRemoveMessage,
  selectionId,
}) => {
  const [open, setOpen] = useState(false);

  const handleMessageClick = (skuCode, isValid) => {
    if (isValid) {
      onRemoveMessage(skuCode);
    }
    scrollToSku(skuCode);
  };

  const scrollToSku = (skuCode) => {
    const skuRow = document.getElementById(`sku-${skuCode}`);
    if (skuRow) {
      // Scroll to the SKU row
      skuRow.scrollIntoView({ behavior: "smooth", block: "center" });

      skuRow.style.backgroundColor = "#ffcccc";

      setTimeout(() => {
        skuRow.style.backgroundColor = "";
      }, 4000);
    } else {
      console.error(`SKU row with id sku-${skuCode} not found.`);
    }
  };

  useEffect(() => {
    // setErrorMessages(initialErrorMessages);
    setOpen(true);
  }, [initialErrorMessages]);

  const toggleDrawer = (isOpen) => {
    setOpen(isOpen);
  };

  return (
    <>
      {!open && (
        <IconButton
          className="animate-bounce hover:animate-none"
          onClick={() => toggleDrawer(true)}
          style={{
            position: "fixed",
            right: 7,
            top: "50%",
            transform: "translateY(-50%)",
            zIndex: 1000,
          }}
        >
          <Badge
            badgeContent={initialErrorMessages.length}
            color="warning"
            overlap="circular"
          >
            <div
              className="bg-gradient-to-r from-red-600 to-red-800 text-white px-4  py-2 rounded-full flex items-center justify-center font-semibold text-sm shadow-xl transform hover:scale-105 hover:shadow-2xl transition-all duration-300 ease-in-out"
              style={{
                fontSize: "14px",
              }}
            >
              ERROR
            </div>
          </Badge>
        </IconButton>
      )}

      <Drawer
        anchor="right"
        open={open}
        onClose={() => toggleDrawer(false)}
        PaperProps={{
          style: {
            width: 380,
            padding: "20px",
            backgroundColor: "#f5f7fa",
            boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
          },
        }}
      >
        {/* Header Section */}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            borderBottom: "1px solid #e0e0e0",
            paddingBottom: 12,
          }}
        >
          <Typography
            variant="h6"
            style={{ color: "#3a4cae", fontWeight: "bold", fontSize: "18px" }}
          >
            Resolve Issues
          </Typography>
          <IconButton
            onClick={() => toggleDrawer(false)}
            style={{ color: "#3a4cae", padding: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </div>
        {/* Content Section */}
        <div style={{ marginTop: 16, marginBottom: 16 }}>
          <Typography
            style={{
              color: "#6c757d",
              fontSize: "14px",
              marginBottom: 12,
            }}
          >
            Please address the following issues before proceeding:
          </Typography>
          <List>
            {initialErrorMessages.map((msg, index) => (
              <ListItem
                button
                key={index}
                onClick={() => {
                  handleMessageClick(
                    msg.sku_code,
                    msg.selection == selectionId
                  );
                  // setOpen(false);
                }}
                style={{
                  padding: "12px",
                  marginBottom: 10,
                  cursor: "pointer",
                  borderRadius: 6,
                  backgroundColor:
                    msg.selection != selectionId ? "#ffebee" : "#C5E7F9",
                  border:
                    msg.selection != selectionId
                      ? "1px solid #ff6b6b"
                      : "1px solid #e0e0e0",
                  boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
                  transition: "transform 0.2s ease",
                }}
                onMouseEnter={(e) =>
                  (e.currentTarget.style.transform = "scale(1.02)")
                }
                onMouseLeave={(e) =>
                  (e.currentTarget.style.transform = "scale(1)")
                }
              >
                <ListItemText
                  primary={
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {msg.selection != selectionId ? (
                        <span
                          style={{
                            color: "#ff6b6b",
                            marginRight: 12,
                            fontSize: "16px",
                            fontWeight: "bold",
                          }}
                        >
                          ⚠️
                        </span>
                      ) : (
                        <span
                          className="animate-ping"
                          style={{
                            color: "#ff6b6b",
                            marginRight: 12,
                            fontSize: "16px",
                            fontWeight: "bold",
                          }}
                        >
                          <GradeIcon style={{ fontSize: "17px" }} />
                        </span>
                      )}
                      <span
                        style={{
                          color:
                            msg.selection != selectionId ? "#ff6b6b" : "#333",
                          fontWeight: 500,
                        }}
                      >
                        {msg.message}
                      </span>
                    </div>
                  }
                />
              </ListItem>
            ))}
          </List>
        </div>
        {/* Footer Section
        <div style={{ marginTop: 20 }}>
          <button
            onClick={() => toggleDrawer(false)}
            style={{
              width: "100%",
              padding: "12px 0",
              backgroundColor: "#3a4cae",
              color: "#ffffff",
              border: "none",
              borderRadius: 6,
              fontWeight: "600",
              cursor: "pointer",
              fontSize: "14px",
              transition: "background-color 0.2s",
            }}
            onMouseEnter={(e) => (e.target.style.backgroundColor = "#2c3e8d")}
            onMouseLeave={(e) => (e.target.style.backgroundColor = "#3a4cae")}
          >
            Close Drawer
          </button>
        </div> */}
      </Drawer>
    </>
  );
};

export default NotificationDrawer;
