import React, { useState, useEffect } from "react";
import UserSection from "../../Services/User/UserLogin";
import UserTopbar from "../UserTopbar";
import ExcelJS from "exceljs";
import animationData from "../../animations/loader_animation0.json";
import Lottie from "lottie-react";
import axios from "axios";
import * as XLSX from "xlsx";
import Loader from "../utils/Loader";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useOutletContext } from "react-router-dom";
import DownloadIcon from "@mui/icons-material/Download";
import DownloadSummary from "./DownloadSummary";
import { Button } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

const ViewProjects = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const params = new URLSearchParams(location.search);
  const project_id = params.get("project_id");
  const myStyles = {
    // display: 'flex',
    // justifyContent: 'center',
    // border:'1px solid #D3D3D3',
    // borderBottom: '1px solid #D3D3D3',
  };
  const [projectId, setprojectId] = useState(project_id);
  const [projectData, setprojectData] = useState();
  const [projectSection, setSection] = useState([]);
  const [sectionArr, setSectionArr] = useState([]);
  const [sectionTotal, setSectionTotal] = useState(0);
  const [sectionId, setSectiionId] = useState(0);
  const [loading, setLoading] = useState(false);
  const [button, setButton] = useState("Download Excel");
  const [disable, setDisable] = useState(false);
  const [file, setFile] = useState(null);
  const [error, setError] = useState("");
  const { isSidebarOpen } = useOutletContext();
  const [excelData, setExcelData] = useState(null);
  // const [clients, setClient] = useState();
  // const [items, setItems] = useState([]);
  // const [project ,setproject] = useState([]);
  const viewprojectById = async () => {
    // e.preventDefault();
    try {
      setLoading(true);
      let res = await UserSection.viewProjectById(projectId);
      if (res.status) {
        setLoading(false);
        const sectionsArray = Object.entries(res.data.section);
        setSectionArr(sectionsArray);
        setprojectData(res.data);
        setSectionTotal(Object.keys(res.data.section).length);
      } else {
        return 0;
      }
    } catch (error) {
      setLoading(true);
      console.log(error);
      // handleApiError(error, 'Failed to fetch data. Please try again later.');
    } finally {
      setLoading(false);
    }
  };
  // import csv file
  const resetstate = (e) => {
    setLoading(false);
    e.preventDefault();
    setprojectId("");
    setSectionArr();
    setSectionTotal();
    setprojectData();
    // window.location.href ='/user/viewProjects';
  };
  useEffect(() => {
    viewprojectById();
  }, []);
  // Helper function to convert a blob to base64

  const formatNumber = (value) => {
    if (typeof value === "string") {
      value = value.replace(/,/g, "");
    }

    value = parseFloat(value);

    //  nearest whole number
    value = Math.round(value);

    // commas
    return value;
  };

  const [dataFromDownloadSummary, setDataFromDownloadSummary] = useState("");
  const [showComponent, setshowComponent] = useState(false);
  const [excelloading, setExcelLoading] = useState(false);
  const [projectIdDownload, setProjectIdDownload] = useState();

  const handleDataFromDownloadSummary = (data) => {
    console.log("data from child" + data);
    setDataFromDownloadSummary(data);
  };

  const downloadexcel = async (id) => {
    try {
      //   setExcelLoading(true);
      setProjectIdDownload(id);
      //   setTimeout(() => {
      //     setExcelLoading(false);
      //   }, 1000);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (
      dataFromDownloadSummary == "downloaded" ||
      dataFromDownloadSummary == "failed"
    ) {
      setExcelLoading(false);
    }
  }, [dataFromDownloadSummary]);

  const editProject = (id) => {
    navigate(`/user/projects/${id}`);
  };
  console.log(JSON.stringify(projectData));

  return (
    <>
      <div>
        {excelloading && <Loader />}
        <UserTopbar heading="View Projects" sidebar={isSidebarOpen} />
        <div className="p-2">
          {/* <section className="flex ">
            <div className="flex flex-col items-center">
              <label htmlFor="projectId">Enter Project Id</label>
              <input
                type="text"
                name="projectId"
                id="projectId"
                value={projectId}
                onChange={(e) => setprojectId(e.target.value)}
              />
            </div>

            <div className="flex gap-4 p-3">
              <button
                type="button"
                className="bg-blue-950 text-white p-3 rounded"
                onClick={viewprojectById}
              >
                Submit
              </button>
              <button
                type="button"
                className="bg-blue-950 text-white p-3 rounded"
                onClick={(e) => resetstate(e)}
              >
                Reset
              </button>
            </div>
          </section> */}
          {error && <p className="text-red-500 text-center">{error}</p>}
          <section>
            <div className="relative p-8">
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
                {/* Client ID */}
                <div className="bg-gradient-to-r from-orange-500 to-red-400 p-4 rounded-lg shadow-md hover:shadow-lg transition-all transform hover:scale-105">
                  <div className="text-white text-sm font-medium mb-2">
                    Client ID
                  </div>
                  <div className="text-white text-lg font-semibold">
                    {projectData ? projectData.client_id : "N/A"}
                  </div>
                </div>

                {/* Client Name */}
                <div className="bg-gradient-to-r from-orange-500 to-red-400 p-4 rounded-lg shadow-md hover:shadow-lg transition-all transform hover:scale-105">
                  <div className="text-white text-sm font-medium mb-2">
                    Client Name
                  </div>
                  <div className="text-white text-lg font-semibold">
                    {projectData ? projectData.client.client_name : "N/A"}
                  </div>
                </div>

                {/* Billing Address */}
                <div className="bg-gradient-to-r from-orange-500 to-red-400 p-4 rounded-lg shadow-md hover:shadow-lg transition-all transform hover:scale-105">
                  <div className="text-white text-sm font-medium mb-2">
                    Billing Address
                  </div>
                  <div className="text-white text-lg font-semibold">
                    {projectData ? projectData.client.site_add : "N/A"}
                  </div>
                </div>

                {/* Architect Firm Detail */}
                <div className="bg-gradient-to-r from-orange-500 to-red-400 p-4 rounded-lg shadow-md hover:shadow-lg transition-all transform hover:scale-105">
                  <div className="text-white text-sm font-medium mb-2">
                    Architect Firm Detail
                  </div>
                  <div className="text-white text-lg font-semibold">
                    {projectData ? projectData.client.afd : "N/A"}
                  </div>
                </div>

                {/* Site Address */}
                <div className="bg-gradient-to-r from-orange-500 to-red-400 p-4 rounded-lg shadow-md hover:shadow-lg transition-all transform hover:scale-105">
                  <div className="text-white text-sm font-medium mb-2">
                    Site Address
                  </div>
                  <div className="text-white text-lg font-semibold">
                    {projectData ? projectData.client.add_to : "N/A"}
                  </div>
                </div>

                {/* Project ID */}
                <div className="bg-gradient-to-r from-orange-500 to-red-400 p-4 rounded-lg shadow-md hover:shadow-lg transition-all transform hover:scale-105">
                  <div className="text-white text-sm font-medium mb-2">
                    Project ID
                  </div>
                  <div className="text-white text-lg font-semibold">
                    {projectData ? projectData.project_id : "N/A"}
                  </div>
                </div>
                <div className="bg-gradient-to-r from-orange-500 to-red-400 p-4 rounded-lg shadow-md hover:shadow-lg transition-all transform hover:scale-105">
                  <div className="text-white text-sm font-medium mb-2">
                    Date
                  </div>
                  <div className="text-white text-lg font-semibold">
                    {projectData
                      ? new Date(projectData.created_at).toLocaleDateString(
                          "en-GB"
                        )
                      : "N/A"}
                  </div>
                </div>
                <div className="flex flex-col items-center gap-2">
                  {/* Download Button */}
                  <Link
                    to={"/user/project/download"}
                    className="bg-white border border-black hover:border-white hover:bg-gray-900 hover:text-white text-gray-950 w-36 h-10 rounded flex justify-center items-center transition duration-300 ease-in-out gap-2"
                  >
                    <DownloadIcon />
                    Download
                  </Link>

                  {/* Edit Button */}
                  <button
                    onClick={() => editProject(projectData.id)}
                    className="bg-white border border-black hover:border-white hover:bg-gray-900 hover:text-white text-gray-950 w-36 h-10 rounded flex justify-center items-center transition duration-300 ease-in-out gap-2"
                  >
                    <EditIcon />
                    Edit
                  </button>
                </div>
              </div>
            </div>

            <table
              class="w-full border-collapse border border-slate-500 ... productTable"
              id="productTable"
            >
              <thead>
                <tr className="bg-gray-900 text-white client_details">
                  <th class=" text-center border border-slate-600 text-left ...">
                    Product Code
                  </th>
                  <th class="text-center border border-slate-600 text-left ...">
                    Brand
                  </th>
                  <th class="text-center border border-slate-600 text-left w-24 ">
                    Product Description{" "}
                  </th>
                  <th class="text-center border border-slate-600 text-left ...">
                    Finish
                  </th>
                  <th class="text-center border border-slate-600 text-left ...">
                    Spare/FG
                  </th>
                  <th class="text-center border border-slate-600 text-left ...">
                    Product Type
                  </th>
                  <th class="text-center border border-slate-600 text-left ...">
                    Product Image
                  </th>
                  <th class="text-center border border-slate-600 text-left ...">
                    Quantity
                  </th>
                  <th class="text-center border border-slate-600 text-left ...">
                    Area
                  </th>
                  <th class="text-center border border-slate-600 text-left ...">
                    MRP (₹)
                  </th>
                  <th class="text-center border border-slate-600 text-left w-24 ">
                    Discount Price (₹)
                  </th>
                  <th class="text-center border border-slate-600 text-left ...">
                    Discount %
                  </th>
                  <th class="text-center border border-slate-600 text-left ...">
                    GST %
                  </th>
                  <th class="text-center border border-slate-600 text-left ...">
                    Total (₹)
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white/90">
                {<div class="excel_loder">{loading && <Loader />}</div>}
                {sectionTotal > 0
                  ? sectionArr.map(([sectionName, data], index) => {
                      let sectionTotalMRP = 0;

                      return (
                        <>
                          {data.map((item, itemindex) => {
                            sectionTotalMRP += item.product_attr.mrp * item.qty;

                            return data.length === 1 ? (
                              <>
                                <tr className="section-heading">
                                  <td
                                    colSpan="14"
                                    className="border bg-gray-800 text-white text-2xl uppercase font bold p-2 text-center"
                                  >
                                    {item.section_name}
                                  </td>
                                </tr>
                                <tr className="s1">
                                  <td className="border p-2 text-center">
                                    {item.sku_code}
                                  </td>
                                  <td className="border p-2 text-center">
                                    {item.product_attr.brand}
                                  </td>
                                  <td className="border p-2 text-center">
                                    {item.product_attr.product_desc}
                                  </td>
                                  <td className="border p-2 text-center">
                                    {item.product_attr.finish}
                                  </td>
                                  <td className="border p-2 text-center">
                                    {item.product_attr.spare_fg}
                                  </td>
                                  <td className="border p-2 text-center">
                                    {item.product_attr.product_typ_group}
                                  </td>
                                  <td className="border p-2 text-center w-24">
                                    <img
                                      src={item.product_attr.image}
                                      className="w-24"
                                    />
                                  </td>
                                  <td className="border p-2 text-center">
                                    {item.qty}
                                  </td>
                                  <td className="border p-2 text-center">
                                    {item.area != "null" ? item.area : ""}
                                  </td>
                                  <td className="border p-2 text-center">
                                    {formatNumber(item.product_attr.mrp)}
                                  </td>
                                  <td className="border p-2 text-center">
                                    {formatNumber(item.disc_price)}
                                  </td>
                                  <td className="border p-2 text-center w-24">
                                    {formatNumber(item.disc_per)}
                                  </td>
                                  <td className="border p-2 text-center w-24">
                                    {item.gst ? item.gst : 0}
                                  </td>
                                  <td className="border p-2 text-center">
                                    {formatNumber(item.total)}
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    colSpan="14"
                                    className="border p-2 text-center text-right"
                                  >
                                    List Price :
                                    {formatNumber(
                                      item.product_attr.mrp * item.qty
                                    )}
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    colSpan="14"
                                    className="border p-2 text-center text-right"
                                  >
                                    Offer Price : {formatNumber(item.total)}{" "}
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    colSpan="14"
                                    className="border p-2 text-center text-right"
                                  >
                                    Shower Area Subtotal:{" "}
                                    {formatNumber(
                                      item.shower_area ? item.shower_area : ""
                                    )}{" "}
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    colSpan="14"
                                    className="border p-2 text-center text-right"
                                  >
                                    Basin Area Subtotal:{" "}
                                    {formatNumber(item.Basin)}
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    colSpan="14"
                                    className="border p-2 text-center text-right"
                                  >
                                    EWC Area Subtotal: {formatNumber(item.Ewc)}{" "}
                                  </td>
                                </tr>

                                <tr>
                                  <td
                                    colSpan="14"
                                    className="border p-2 text-center text-right"
                                  >
                                    Kitchen Area Subtotal:{" "}
                                    {formatNumber(item.kitchen)}{" "}
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    colSpan="14"
                                    className="border p-2 text-center text-right"
                                  >
                                    Faucets Area Subtotal:{" "}
                                    {formatNumber(item.faucets)}{" "}
                                  </td>
                                </tr>
                              </>
                            ) : itemindex === 0 ? (
                              <>
                                <tr className="section-heading">
                                  <td
                                    colSpan="14"
                                    className="border font-serif  bg-gray-800 text-white text-2xl font bold uppercase p-2 text-center"
                                  >
                                    {item.section_name}
                                  </td>
                                </tr>
                                <tr className="s1">
                                  <td className="border p-2 text-center">
                                    {item.sku_code}
                                  </td>
                                  <td className="border p-2 text-center">
                                    {item.product_attr.brand}
                                  </td>
                                  <td className="border p-2 text-center">
                                    {item.product_attr.product_desc}
                                  </td>
                                  <td className="border p-2 text-center">
                                    {item.product_attr.finish}
                                  </td>
                                  <td className="border p-2 text-center">
                                    {item.product_attr.spare_fg}
                                  </td>
                                  <td className="border p-2 text-center">
                                    {item.product_attr.product_typ_group}
                                  </td>
                                  <td
                                    className="border p-2 text-center w-24"
                                    style={myStyles}
                                  >
                                    <img
                                      src={item.product_attr.image}
                                      className="w-24"
                                    />
                                  </td>
                                  <td className="border p-2 text-center">
                                    {item.qty}
                                  </td>
                                  <td className="border p-2 text-center">
                                    {item.area != "null" ? item.area : ""}
                                  </td>
                                  <td className="border p-2 text-center">
                                    {item.product_attr.mrp}
                                  </td>
                                  <td className="border p-2 text-center">
                                    {item.disc_price.toFixed(2)}
                                  </td>
                                  <td className="border p-2 text-center w-24">
                                    {item.disc_per.toFixed(2)}
                                  </td>
                                  <td className="border p-2 text-center w-24">
                                    {item.gst ? item.gst : 0}
                                  </td>
                                  <td className="border p-2 text-center">
                                    {item.total.toFixed(2)}
                                  </td>
                                </tr>
                              </>
                            ) : itemindex + 1 === data.length ? (
                              <>
                                <tr className="s1">
                                  <td className="border p-2 text-center">
                                    {item.sku_code}
                                  </td>
                                  <td className="border p-2 text-center">
                                    {item.product_attr.brand}
                                  </td>
                                  <td className="border p-2 text-center">
                                    {item.product_attr.product_desc}
                                  </td>
                                  <td className="border p-2 text-center">
                                    {item.product_attr.finish}
                                  </td>
                                  <td className="border p-2 text-center">
                                    {item.product_attr.spare_fg}
                                  </td>
                                  <td className="border p-2 text-center">
                                    {item.product_attr.product_typ_group}
                                  </td>
                                  <td
                                    className="border p-2 text-center w-24"
                                    style={myStyles}
                                  >
                                    <img
                                      src={item.product_attr.image}
                                      className="w-24"
                                    />
                                  </td>
                                  <td className="border p-2 text-center">
                                    {item.qty}
                                  </td>
                                  <td className="border p-2 text-center">
                                    {item.area != "null" ? item.area : ""}
                                  </td>
                                  <td className="border p-2 text-center">
                                    {formatNumber(item.product_attr.mrp)}
                                  </td>
                                  <td className="border p-2 text-center">
                                    {item.disc_price
                                      ? formatNumber(item.disc_price)
                                      : ""}
                                  </td>
                                  <td className="border p-2 text-center w-24">
                                    {item.disc_per
                                      ? formatNumber(item.disc_per)
                                      : ""}
                                  </td>
                                  <td className="border p-2 text-center w-24">
                                    {item.gst ? item.gst : 0}
                                  </td>
                                  <td className="border p-2 text-center">
                                    {item.total ? formatNumber(item.total) : ""}
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    colSpan="14"
                                    className="border p-2 text-center text-right"
                                  >
                                    List Price :{formatNumber(sectionTotalMRP)}
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    colSpan="14"
                                    className="border p-2 text-center text-right"
                                  >
                                    Offer Price : {formatNumber(item.totals)}{" "}
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    colSpan="14"
                                    className="border p-2 text-center text-right"
                                  >
                                    Shower Area Subtotal:{" "}
                                    {formatNumber(
                                      item.shower_area ? item.shower_area : ""
                                    )}{" "}
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    colSpan="14"
                                    className="border p-2 text-center text-right"
                                  >
                                    Basin Area Subtotal:{" "}
                                    {formatNumber(item.Basin)}
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    colSpan="14"
                                    className="border p-2 text-center text-right"
                                  >
                                    EWC Area Subtotal: {formatNumber(item.Ewc)}
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    colSpan="14"
                                    className="border p-2 text-center text-right"
                                  >
                                    Kitchen Area Subtotal:{" "}
                                    {formatNumber(item.kitchen)}{" "}
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    colSpan="14"
                                    className="border p-2 text-center text-right"
                                  >
                                    Faucets Area Subtotal:{" "}
                                    {formatNumber(item.faucets)}{" "}
                                  </td>
                                </tr>
                              </>
                            ) : (
                              <tr>
                                <td className="border p-2 text-center">
                                  {item.sku_code}
                                </td>
                                <td className="border p-2 text-center">
                                  {item.product_attr.brand}
                                </td>
                                <td className="border p-2 text-center">
                                  {item.product_attr.product_desc}
                                </td>
                                <td className="border p-2 text-center">
                                  {item.product_attr.finish}
                                </td>
                                <td className="border p-2 text-center">
                                  {item.product_attr.spare_fg}
                                </td>
                                <td className="border p-2 text-center">
                                  {item.product_attr.product_typ_group}
                                </td>
                                <td
                                  className="border p-2 text-center w-24"
                                  style={myStyles}
                                >
                                  <img
                                    src={item.product_attr.image}
                                    className="w-24"
                                  />
                                </td>
                                <td className="border p-2 text-center">
                                  {item.qty}
                                </td>
                                <td className="border p-2 text-center">
                                  {item.area != "null" ? item.area : ""}
                                </td>
                                <td className="border p-2 text-center">
                                  {formatNumber(item.product_attr.mrp)}
                                </td>
                                <td className="border p-2 text-center">
                                  {formatNumber(item.disc_price)}
                                </td>
                                <td className="border p-2 text-center w-24">
                                  {formatNumber(item.disc_per)}
                                </td>
                                <td className="border p-2 text-center w-24">
                                  {item.gst ? formatNumber(item.gst) : 0}
                                </td>
                                <td className="border p-2 text-center">
                                  {formatNumber(item.total)}
                                </td>
                              </tr>
                            );
                          })}
                        </>
                      );
                    })
                  : "No Data found"}
              </tbody>
            </table>
          </section>
        </div>
      </div>
      <div style={{ display: showComponent ? "block" : "none" }}>
        <DownloadSummary
          project={projectIdDownload}
          sendData={handleDataFromDownloadSummary}
        />
      </div>
    </>
  );
};

export default ViewProjects;
