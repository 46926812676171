import { useEffect, useState } from "react";
import UserSection from "../../Services/User/UserLogin";
import { handleApiError } from "../../Helpers/handleApiError ";
import Cookies from "js-cookie";
import Swal from "sweetalert2";
import AppUser from "../AppUser";
import { Link } from "react-router-dom";
import Carousel from "../Carousel";
import Lottie from "lottie-react";
import { useNavigate } from "react-router-dom";
import animationData1 from "../../animations/loader_animation.json";
import animationData from "../../animations/loader_animation0.json";
import img1 from "../../../src/SliderImage/1.jpg";
import img3 from "../../../src/SliderImage/3.jpg";
import img4 from "../../../src/SliderImage/4.jpg";
import img5 from "../../../src/SliderImage/5.jpg";
import img6 from "../../../src/SliderImage/6.jpg";
import img7 from "../../../src/SliderImage/7.jpg";
import img8 from "../../../src/SliderImage/8.jpg";
import grohe from "../../../src/clientlogo/index_grohe_logo.jpg";
import americanStandard from "../../../src/clientlogo/index_as_logo.gif";
import lixil from "../../../src/clientlogo/index_lixil_logo.gif";
import Loader from "../utils/Loader";
import { gsap } from "gsap"; // GSAP import

const images = [img1, img3, img4, img5, img6, img7, img8];

export default function UserLogin() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const [isStopped, setStopped] = useState(false);
  const [Paused, setPaused] = useState(false);
  const [loading, setLoading] = useState(false);

  const toggleProfileMenu = () => {
    setShowProfileMenu(!showProfileMenu);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await UserSection.userLogin({ email, password });
      console.log(response);
      if (
        response.status &&
        response.data.data.role_id == 2 &&
        response.data.data.is_active == 1
      ) {
        localStorage.setItem("user", response.data.token);
        localStorage.setItem("user_name", response.data.data.name);
        Swal.fire({
          icon: "success",
          title: "Login",
          text: "Wel-come" + "   " + response.data.data.name,
          timer: 1000,
        });
        window.location.href = "/user";
      } else {
        navigate("/login");
      }
      setLoading(false);
    } catch (error) {
      handleApiError(error, "Failed to fetch data. Please try again later.");
    }
  };

  const handleLogout = () => {
    Cookies.remove("token");
    localStorage.removeItem("user");
    setIsActive(false);
  };

  const clientLogos = [
    { src: grohe, alt: "Grohe" },
    { src: americanStandard, alt: "American Standard" },
    { src: lixil, alt: "Lixil" },
  ];

  useEffect(() => {
    // GSAP animations for page elements with dynamic, visually appealing transitions

    // Animate the login header with a more dramatic movement and fade-in
    gsap.from(".login-header", {
      duration: 1.8,
      opacity: 0,
      y: -120, // Strong upward movement
      rotationX: 20, // Slight 3D rotation for an impactful entrance
      ease: "back.out(1.7)", // Intense easing for a bouncing effect
      delay: 0.3, // Small delay to set the tone
    });

    // Input fields animate in with scaling and a more intense sliding motion
    gsap.from(".input-field", {
      duration: 1.4,
      opacity: 0,
      x: -150, // Strong left-to-right movement
      scale: 0.85, // Scale up effect to make them pop
      stagger: 0.2, // Slight delay between each field
      ease: "expo.out", // Fast easing for a punchy entrance
      rotation: 15, // Slight rotation to make it dynamic
    });

    // Login button slides up with a pulsating effect and colorful shadow
    gsap.from(".login-button", {
      duration: 1.6,
      opacity: 0,
      y: 80,
      scale: 0.9, // Scale-up for emphasis
      ease: "bounce.out(1.5)", // Bouncy easing for energy
      boxShadow:
        "0px 10px 40px rgba(0, 255, 0, 0.3), 0px 5px 15px rgba(0, 0, 255, 0.3)", // Multi-color glow for attention
      delay: 0.7, // Slight delay to create visual flow
    });

    // Carousel container slides in with a rotating and fading effect for depth
    gsap.from(".carousel-container", {
      duration: 1.8,
      opacity: 0,
      x: 200, // Strong slide-in effect
      rotation: 25, // Slight rotation for a more dynamic entrance
      ease: "elastic.out(1, 0.5)", // Bouncy and elastic easing
      delay: 1, // Larger delay to bring it in last
    });

    // Parallax effect for the background (creating depth)
    gsap.to(".background", {
      scrollTrigger: {
        trigger: ".login-container", // Define the section to trigger parallax
        start: "top bottom", // Start when the element is in the viewport
        end: "bottom top",
        scrub: 0.5, // Smooth scroll scrub for parallax
      },
      y: 150, // Background moves slower for depth illusion
      ease: "none",
    });
  }, []);

  return (
    <>
      {loading && (
        <div className="loder">
          <Loader />
        </div>
      )}
      <Carousel images={images} />{" "}
      <center className="">
        <div className="max-w-md m-auto bg-gray-950/50 rounded-lg mt-[10%]">
          <div className="flex min-h-full flex-1 flex-col justify-center px-6 lg:px-8 py-12 mt-20">
            {/* Ensure the carousel is wrapped correctly */}

            <div className="sm:mx-auto sm:w-full sm:max-w-sm">
              <h2 className="text-center text-2xl font-bold leading-9 tracking-tight text-white login-header">
                Sign in to your account
              </h2>
            </div>

            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
              <form className="space-y-6" action="#" method="POST">
                <div className="input-field">
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium leading-6 text-white"
                  >
                    Email Address
                  </label>
                  <div className="mt-2">
                    <input
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="email"
                      required
                      className="text-center block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>

                <div className="input-field">
                  <div className="flex items-center justify-between">
                    <label
                      htmlFor="password"
                      className="block text-sm font-medium leading-6 text-white"
                    >
                      Password
                    </label>
                    <div className="text-sm">
                      <a
                        href="/forgotPassword"
                        className="font-semibold text-indigo-600 hover:text-indigo-500"
                      >
                        Forgot password?
                      </a>
                    </div>
                  </div>
                  <div className="mt-2">
                    <input
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      id="password"
                      name="password"
                      type="password"
                      autoComplete="current-password"
                      required
                      className="text-center block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>

                <div className="input-field">
                  <button
                    onClick={handleLogin}
                    type="submit"
                    className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 login-button"
                  >
                    Sign in
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div className="fixed top-4 left-4 p-4 rounded-lg shadow-lg border border-gray-200 pointer-events-none">
            {/* First Row: Two Images */}
            <div className="flex justify-between gap-4 mb-3">
              {clientLogos.slice(0, 2).map((logo, index) => (
                <div
                  key={index}
                  className="p-2 "
                  style={{
                    backgroundColor: index === 0 ? "#111F39" : "#fff",
                  }}
                >
                  <img src={logo.src} alt={logo.alt} className="w-16 h-8" />
                </div>
              ))}
            </div>

            {/* Divider */}
            <hr className="border-t border-gray-200 mb-3" />

            {/* Third Image and Text */}
            <div className="flex justify-end items-center">
              <p className="text-white text-xs font-semibold">PART OF</p>
              <div className="p-2 ">
                <p className="text-orange-500 bg-white p-1 text-xs font-semibold">
                  LIXIL
                </p>
              </div>
            </div>
          </div>
        </div>
      </center>
    </>
  );
}
