import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
import { NavLink, Outlet } from "react-router-dom";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import { Tooltip } from "@mui/material";
import { CgMinimize } from "react-icons/cg";
import { RxTextAlignLeft } from "react-icons/rx";
import bgImg from "../../../src/SliderImage/4.jpg";
import bgVideo from "../../../src/bg-video/2.mp4";
const UserLayout = () => {
  const location = useLocation();
  const [activeLink, setActiveLink] = useState("/user");
  const [isSidebarOpen, setIsSidebarOpen] = useState(true); // Sidebar open/close state

  useEffect(() => {
    if (location.pathname !== "/user") {
      setIsSidebarOpen(false);
    }
  }, [location.pathname]);
  // Handle click on a sidebar link
  const onSidebarClick = (link) => {
    setActiveLink(link);
  };

  // Update activeLink based on the current URL
  useEffect(() => {
    setActiveLink(location.pathname);
  }, [location.pathname]);

  // Toggle the sidebar's open/close state
  const toggleSidebar = () => {
    setIsSidebarOpen((prevState) => !prevState);
  };

  return (
    <>
      {/* bg image */}
      <div className="absolute inset-0 -z-20">
        <video
          src={bgVideo}
          autoPlay
          loop
          muted
          className={`w-full h-full object-cover object-center fixed  ${
            location.pathname !== "/user" ? "" : " "
          }`}
        />
        {/* <div className="absolute inset-0 bg-black opacity-10"></div> */}
      </div>

      <div className="flex gap-11 overflow-x-hidden w-full">
        <div
          className={` fixed h-screen max-w-[64rem] z-50 ${
            location.pathname !== "/user" ? "flex" : "hidden"
          }`}
        >
          {/* Sidebar */}
          <nav
            className={`px-2 h-screen bg-gray-900 text-white  transition-all duration-300 ${
              isSidebarOpen ? "w-56" : "w-[5rem]"
            }`}
          >
            {/* Toggle Button */}
            <div className={`pt-4 ${isSidebarOpen ? "ml-[80%]" : ""}`}>
              <button
                className="text-white text-3xl ml-2"
                onClick={toggleSidebar}
              >
                {isSidebarOpen ? (
                  <CgMinimize style={{ fontSize: "30px" }} />
                ) : (
                  <RxTextAlignLeft style={{ fontSize: "30px" }} />
                )}
              </button>
            </div>

            {/* Sidebar Links */}
            <ul className="space-y-4 pt-6 text-left text-base">
              <li>
                <Link
                  to="/user"
                  className={`${
                    activeLink === "/user"
                      ? "flex gap-1 p-2 rounded-xl active"
                      : "flex gap-1 p-2 rounded-xl"
                  } py-4`}
                  onClick={() => onSidebarClick("/user")}
                >
                  <Tooltip title={"Home"} arrow>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6 ml-2"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                      />
                    </svg>
                  </Tooltip>
                  {isSidebarOpen && "Home"}
                </Link>
              </li>
              <li>
                <Link
                  to="/user/viewclient"
                  className={`${
                    activeLink === "/user/viewclient"
                      ? "flex gap-1 p-2 rounded-xl active"
                      : "flex gap-1 p-2 rounded-xl"
                  } py-4`}
                  onClick={() => onSidebarClick("/user/viewclient")}
                >
                  <Tooltip title={"Add /View Client"} arrow>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6 ml-2"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 4.5C7.254 4.5 3.5 7.5 3.5 12s3.754 7.5 8.5 7.5 8.5-3 8.5-7.5-3.754-7.5-8.5-7.5zM12 16.5c-2.485 0-4.5-2.015-4.5-4.5s2.015-4.5 4.5-4.5 4.5 2.015 4.5 4.5-2.015 4.5-4.5 4.5z"
                      />
                    </svg>
                  </Tooltip>
                  {isSidebarOpen && "Add /View Client"}
                </Link>
              </li>

              {activeLink.startsWith("/user/projects/") ? (
                <li>
                  <a
                    href="/user/projects"
                    className={`${
                      activeLink.startsWith("/user/projects")
                        ? "flex gap-1 p-2 rounded-xl active"
                        : "flex gap-1 p-2 rounded-xl"
                    } py-4`}
                    onClick={() => onSidebarClick("/user/projects")}
                  >
                    <Tooltip title={"Create Quotation"} arrow>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6 ml-2"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M21 7.5l-9-5.25L3 7.5m18 0l-9 5.25m9-5.25v9l-9 5.25M3 7.5l9 5.25M3 7.5v9l9 5.25m0-9v9"
                        />
                      </svg>
                    </Tooltip>

                    {isSidebarOpen && "Create Quotation"}
                  </a>
                </li>
              ) : (
                <li>
                  <Link
                    to="/user/projects"
                    className={`${
                      activeLink.startsWith("/user/projects")
                        ? "flex gap-1 p-2 rounded-xl active"
                        : "flex gap-1 p-2 rounded-xl"
                    } py-4`}
                    onClick={() => onSidebarClick("/user/projects")}
                  >
                    <Tooltip title={"Create Quotation"} arrow>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6 ml-2"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M21 7.5l-9-5.25L3 7.5m18 0l-9 5.25m9-5.25v9l-9 5.25M3 7.5l9 5.25M3 7.5v9l9 5.25m0-9v9"
                        />
                      </svg>
                    </Tooltip>

                    {isSidebarOpen && "Create Quotation"}
                  </Link>
                </li>
              )}

              <li>
                <Link
                  to="/user/summary"
                  className={`${
                    activeLink === "/user/summary"
                      ? "flex gap-1 p-2 rounded-xl active"
                      : "flex gap-1 p-2 rounded-xl"
                  } py-4`}
                  onClick={() => onSidebarClick("/user/summary")}
                >
                  <Tooltip title={"Summary"} arrow>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6 ml-2"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
                      />
                    </svg>
                  </Tooltip>

                  {isSidebarOpen && "Summary"}
                </Link>
              </li>
              {/* <li>
                <Link
                  to="/user/viewProjects"
                  className={`${activeLink === "/user/viewProjects"
                      ? "flex gap-1 p-2 rounded-xl active"
                      : "flex gap-1 p-2 rounded-xl"
                    } py-4`}
                  onClick={() => onSidebarClick("/user/viewProjects")}
                >
                  <Tooltip title={"View Projects"} arrow>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6 ml-2"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 4.5C7.254 4.5 3.5 7.5 3.5 12s3.754 7.5 8.5 7.5 8.5-3 8.5-7.5-3.754-7.5-8.5-7.5zM12 16.5c-2.485 0-4.5-2.015-4.5-4.5s2.015-4.5 4.5-4.5 4.5 2.015 4.5 4.5-2.015 4.5-4.5 4.5z"
                      />
                    </svg>
                  </Tooltip>

                  {isSidebarOpen && "View Projects"}
                </Link>
              </li> */}
              <li>
                <Link
                  to="/user/help-desk"
                  className={`${
                    activeLink === "/user/help-desk"
                      ? "flex gap-1  p-2 rounded-xl active"
                      : "flex gap-1  p-2 rounded-xl"
                  } py-4`}
                  onClick={() => onSidebarClick("/user/help-desk")}
                >
                  <Tooltip title={"Helpdesk"} arrow>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6 ml-2"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                      />
                    </svg>
                  </Tooltip>

                  {isSidebarOpen && "Helpdesk"}
                </Link>
              </li>
            </ul>

            {/* Footer */}
            {isSidebarOpen && (
              <div className="absolute bottom-0 p-3">
                <span class="text-sm">Version 2.0.0.0&nbsp;</span>
                <br />

                <span className="text-sm">Made with&nbsp;</span>
                <FontAwesomeIcon
                  icon={faHeart}
                  size="1x"
                  style={{ color: "red" }}
                />
                <span className="text-sm"> by</span>
                <br />
                <span className="text-sm">Ruminate IT Solution</span>
              </div>
            )}
          </nav>
        </div>

        <div
          className={`flex-1 transition-all duration-300 ${
            isSidebarOpen ? "ml-56" : "ml-20"
          } ${
            location.pathname !== "/user" ? "p-1 mt-16" : " ml-0"
          } overflow-x-hidden `}
        >
          <Outlet context={{ isSidebarOpen }} />
        </div>
      </div>
    </>
  );
};

export default UserLayout;
