import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DownloadSummary from './DownloadSummary';
import Loader from '../utils/Loader';
import UserTopbar from '../UserTopbar';

const ViewProjectDownload = () => {
    const projectId = localStorage.getItem('projectId');
    const [dataFromDownloadSummary, setDataFromDownloadSummary] = useState("");
    const [excelloading, setExcelLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        if (dataFromDownloadSummary === "downloaded" || dataFromDownloadSummary === "failed") {
            setExcelLoading(false);
            navigate(-1);
        }
    }, [dataFromDownloadSummary, navigate]);

    const handleDataFromDownloadSummary = (data) => {
        console.log("Data from child:", data);
        setDataFromDownloadSummary(data);
    };

    return (
        <>
            <UserTopbar heading="View Projects" />
            {excelloading && (
                <div className="absolute inset-0 flex flex-col items-center justify-center">
                    <Loader />
                    <p className="mt-32 text-xl font-semibold text-white ">
                        Generating Excel, please wait...
                    </p>
                </div>
            )}
            <div className="hidden">
                <DownloadSummary
                    project={projectId}
                    sendData={handleDataFromDownloadSummary}
                />
            </div>
        </>
    );
};

export default ViewProjectDownload;
