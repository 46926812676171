import React, { useEffect, useState } from 'react';
import MainLayout from './MainLayout';
import UserService from "../../Services/UserList";
import Swal from "sweetalert2";
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination,
    TextField, Paper, Box
} from '@mui/material';
import Loader from '../utils/Loader';

const Projects = () => {
    const [projects, setProjects] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        projectsListApi();
    }, []);

    const projectsListApi = async () => {
        try {
            setLoading(true);
            const response = await UserService.Projects();
            setProjects(response.data);
            setLoading(false);
        } catch (error) {
            Swal.fire({
                icon: "warning",
                title: "Warning",
                text: error.message,
                timer: 3000,
            });
            setLoading(false);
        }
    };

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [searchTerm, setSearchTerm] = useState("");

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0); // Reset to first page when rows per page changes
    };

    // Filter data based on search term
    const filteredProjects = projects?.filter(project =>
        project.client_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        project.project_id.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <>
            {loading && (<Loader />)}
            <MainLayout>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginBottom: 3,
                        padding: 2
                    }}>
                    <TextField
                        label="Search Projects"
                        variant="outlined"
                        size="small"
                        value={searchTerm}
                        onChange={handleSearchChange}
                        sx={{
                            width: '100%',
                            maxWidth: 400,
                            // borderRadius: '50px',
                            backgroundColor: '#f0f0f0',
                            boxShadow: '0px 4px 6px rgba(0,0,0,0.1)',
                        }}
                    />
                </Box>

                <Paper elevation={3} sx={{ margin: "1rem", padding: "1rem" }}>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ fontWeight: 'bold' }}>SL No.</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold' }}>Client</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold' }}>Project ID</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold' }}>Created At</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {filteredProjects?.length > 0 ? (
                                    filteredProjects.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map(({ id, client_name, project_id, created_at }, index) => (
                                            <TableRow key={id}>
                                                <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                                                <TableCell>{client_name}</TableCell>
                                                <TableCell>{project_id}</TableCell>
                                                <TableCell>{new Date(created_at).toLocaleString()}</TableCell>
                                            </TableRow>
                                        ))
                                ) : (
                                    <TableRow>
                                        <TableCell colSpan={6} align="center">No projects found</TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <TablePagination
                        rowsPerPageOptions={[10, 25, 100, 500, filteredProjects?.length]}
                        component="div"
                        count={filteredProjects?.length || 0}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        sx={{
                            paddingTop: 2,
                            paddingBottom: 2,
                        }}
                    />
                </Paper>
            </MainLayout>
        </>
    );
};

export default Projects;
