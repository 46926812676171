import { useState } from "react";
import { Tooltip } from "@mui/material";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import AspectRatioIcon from "@mui/icons-material/AspectRatio";
import grohe from "../../src/clientlogo/index_grohe_logo.jpg";
import americanStandard from "../../src/clientlogo/index_as_logo.gif";
import lixil from "../../src/clientlogo/index_lixil_logo.gif";
import { useNavigate } from "react-router-dom";

const UserTopbar = ({ heading, sidebar }) => {
  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const navigate = useNavigate();

  const clientLogos = [
    { src: grohe, alt: "Grohe" },
    { src: americanStandard, alt: "American Standard" },
    // { src: lixil, alt: "Lixil" },
  ];

  const handleFullscreen = () => {
    const doc = window.document;
    const docEl = doc.documentElement;

    if (
      !doc.fullscreenElement &&
      !doc.mozFullScreenElement &&
      !doc.webkitFullscreenElement
    ) {
      if (docEl.requestFullscreen) {
        docEl.requestFullscreen();
      } else if (docEl.mozRequestFullScreen) {
        docEl.mozRequestFullScreen();
      } else if (docEl.webkitRequestFullscreen) {
        docEl.webkitRequestFullscreen();
      }
      setIsFullscreen(true);
    } else {
      if (doc.exitFullscreen) {
        doc.exitFullscreen();
      } else if (doc.mozCancelFullScreen) {
        doc.mozCancelFullScreen();
      } else if (doc.webkitExitFullscreen) {
        doc.webkitExitFullscreen();
      }
      setIsFullscreen(false);
    }
  };

  const toggleProfileMenu = (e) => {
    e.preventDefault();
    setShowProfileMenu(!showProfileMenu);
  };

  const handleLogout = () => {
    localStorage.removeItem("user");
    navigate("/");
  };

  return (
    <div
      className={`fixed ${
        sidebar ? "ml-56" : "ml-20"
      } top-0 left-0 right-0 z-50 transition-all duration-300 flex items-center justify-between px-4 py-2 h-16 bg-gray-900 text-white shadow-md`}
    >
      {/* Logos Section */}
      <div
        className="flex gap-6 cursor-pointer"
        onClick={() => navigate("/user")}
      >
        {clientLogos.map((logo, index) => (
          <div
            key={index}
            className="p-2  shadow-md flex items-center justify-center"
            style={{
              backgroundColor: index === 0 ? "#111F39" : "#fff",
            }}
          >
            <img
              src={logo.src}
              alt={logo.alt}
              className="w-12 h-8 object-contain"
            />
          </div>
        ))}
      </div>
      {/* Heading Section */}
      <div className="flex items-center gap-2 bg-gradient-to-r bg-white p-1 rounded">
        <p className="text-orange-600  px-2 py-1 text-sm font-bold rounded-md shadow-sm">
          LI<span className="text-base">X</span>IL
        </p>
        <h1 className="uppercase text-gray-900 font-semibold">{heading}</h1>
      </div>
      {/* Profile and Fullscreen Options */}
      <div className="flex items-center gap-4">
        <p className="text-lg uppercase font-medium">
          {localStorage.getItem("user_name")}
        </p>
        <button
          type="button"
          className="hover:text-yellow-500 hover:scale-110 transition-all duration-300"
          onClick={toggleProfileMenu}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-7 h-7"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z"
            />
          </svg>
        </button>
        {isFullscreen ? (
          <Tooltip title="Exit Fullscreen" arrow>
            <ExitToAppIcon
              className="hover:text-yellow-500 hover:scale-125 transition-all duration-300 cursor-pointer"
              onClick={handleFullscreen}
            />
          </Tooltip>
        ) : (
          <Tooltip title="Fullscreen" arrow>
            <AspectRatioIcon
              className="hover:text-yellow-500 hover:scale-125 transition-all duration-300 cursor-pointer"
              onClick={handleFullscreen}
            />
          </Tooltip>
        )}
        {showProfileMenu && (
          <div className="absolute  top-20 bg-gray-950 right-4 border border-gray-300 rounded-md shadow-lg py-2 px-4 text-sm">
            <ul>
              <li className="mb-2">
                <a
                  href="/user/change-password"
                  className="text-white hover:text-yellow-500"
                >
                  Change Password
                </a>
              </li>
              <li>
                <button
                  onClick={handleLogout}
                  className="text-white hover:text-yellow-500"
                >
                  Logout
                </button>
              </li>
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserTopbar;
